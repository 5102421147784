import React from 'react'

// images
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-wrapper">
                <div className="container text-center">
                    <div className="row">
                        <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                            <div className="footer-copyright h5">
                                <p>&copy; Copyright 2023 2MXH.COM. All Rights Reserved.</p>
                                {/* <p className='strong'>Hệ thống được phát triển và vận hành bởi <a href="#!">Trương Quốc Bảo</a></p> */}
                                <p>We accept: Visa, Master Card, Stripe, Perfect Money, Payeer, and Coinbase.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-pattern" style={{ backgroundImage: `url(${background_image_1})` }}></div>
            </div>
        </footer>
    )
}

export default Footer
