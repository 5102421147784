const features = [
    {
        icon: 'las la-money-bill-wave',
        heading: 'Giá cả hợp lý',
        excerpt: 'Bảng điều khiển SMM của chúng tôi cung cấp giải pháp tiết kiệm chi phí cho nhu cầu quảng bá mạng xã hội của bạn.',
        to: null,
    },
    {
        icon: 'las la-user-friends',
        heading: 'Giao diện thân thiện',
        excerpt: 'Bảng điều khiển của chúng tôi rất dễ sử dụng và dễ quản lý, giúp cho bạn dễ dàng quản lý các tài khoản mạng xã hội của mình.',
        to: null,
    },
    {
        icon: 'las la-headset',
        heading: 'Hỗ trợ 24/7',
        excerpt: 'Đội ngũ hỗ trợ của chúng tôi luôn sẵn sàng hỗ trợ bạn với bất kỳ câu hỏi hoặc vấn đề mà bạn có thể gặp phải một cách nhanh chóng .',
        to: null,
    },
    {
        icon: 'las la-server',
        heading: 'Công nghệ',
        excerpt: 'Hệ thống được phát triển và tự động hoá, giúp cho việc quản lý và phát triển mạng xã hội của bạn trở nên dễ dàng hơn.',
        to: null,
    },
    {
        icon: 'las la-user-lock',
        heading: 'Bảo mật',
        excerpt: 'Chúng tôi luôn đảm bảo tính bảo mật của tài khoản của bạn, không bao giờ chia sẻ thông tin của bạn với bất kỳ ai.',
        to: null,
    },
    {
        icon: 'las la-rocket',
        heading: 'Dịch vụ',
        excerpt: 'Chúng tôi cung cấp đa dạng dịch vụ, bao gồm Facebook, TikTok, YouTube, Twitter, Telegram và nhiều nữa.',
        to: null,
    },
]

export default features
