import React from 'react'

// import Swiper React components
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

// images
import testimonial_image_1 from '../../assets/images/testimonial-slide-img-2.png'

const TestimonialSlider = () => {
    const data = [
        {
            image: testimonial_image_1,
            testimony: '“Unbeliveable prices our prices most Reasonable in the marketStarting From at 0.001S”',
            name: 'Natasha',
        },
    ]

    return (
        <div className="testimonial-slider">
            <Swiper
                className="swiper-container"
                resizeObserver={true}
                spaceBetween={0}
                initialSlide={1}
                // init={false}
                speed={250}
                slideToClickedSlide={true}
                slidesPerView={1.7}
                loop={true}
                centeredSlides={true}
                breakpoints={{
                    0: {
                        direction: 'horizontal',
                    },
                    992: {
                        direction: 'vertical',
                    },
                }}
            >
                {data.map((element, key) => {
                    return (
                        <SwiperSlide key={key}>
                            <div className="testimonial-slide">
                                <div className="image">
                                    <div className="image-wrapper">
                                        <div className="image-inner">
                                            <img src={element.image} alt="testimony" />
                                        </div>
                                    </div>
                                </div>
                                <div className="content">
                                    <p>{element.testimony}</p>
                                    <h5>— {element.name}</h5>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default TestimonialSlider
