import React from 'react'

import PageHeader from '../components/PageHeader'
import BackgroundAnimation from '../components/BackgroundAnimation'
import SectionHeading from '../components/SectionHeading'
import PricingSlider from '../components/PricingSlider'

import background_image_1 from '../../assets/images/patterns/pattern-1.jpg'

const Pricing = () => {
    return (
        <>
            {/* page header - start */}
            <PageHeader
                title="Ưu đãi khách hàng"
                pages={[
                    {
                        title: 'Trang chủ',
                        to: '/',
                    },
                    {
                        title: 'Nâng cấp',
                        to: '/pricing',
                    },
                ]}
            />
            {/* page header - end */}

            {/* pricing section - start */}
            <div className="pricing-section">
                <div className="pricing-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading icon="las la-tags" heading="our app rates" subHeading="Pricing plans for you." additionalClasses="center c-white" mainHeadingClass="c-white" subHeadingClass="c-white" />
                            </div>
                        </div>
                        <PricingSlider />
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation />
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{ backgroundImage: `url(${background_image_1})` }}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* pricing section - end */}
        </>
    )
}

export default Pricing
