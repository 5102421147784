import React from 'react'

// components
import DownloadButton from '../components/DownloadButton'
import SectionHeading from '../components/SectionHeading'
import AppFeatureSingle from '../components/AppFeatureSingle'
import PricingSlider from '../components/PricingSlider'
import BackgroundAnimation from '../components/BackgroundAnimation'

// images
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg'
import top_img from '../../assets/images/top-img.webp'
// data
import features from '../data/features'

const Index = () => {
    return (
        <>
            {/* hero - start */}
            <div className="hero hero-1 feature-section feature-section-0">
                <div className="hero-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                                <div className="hero-content">
                                    <h1 className="c-dark">Best SMM Panel for Social Media</h1>
                                    <p className="large c-grey">
                                        Trang web của chúng tôi cung cấp giải pháp SMM rẻ và hiệu quả, giúp bạn cải thiện tốt hơn trong việc quản lý và phát triển mạng xã hội của mình. Với những công cụ và dịch vụ được
                                        cung cấp, bạn có thể dễ dàng tăng lượng người theo dõi, thích, xem cho tài khoản của mình một cách hiệu quả.
                                    </p>
                                    <DownloadButton color="c-white" />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 order-lg-2 col-10 offset-1 order-1">
                                <div className="hero-image">
                                    <div className="feature-section-image">
                                        {/* <img src={feature_image_1} className="image" alt="image" /> */}
                                        <img src={top_img} className="phone" alt="phone" />
                                        <div className="background-pattern background-pattern-radius">
                                            <BackgroundAnimation />
                                            <div className="background-pattern-gradient"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* hero - end */}

            {/* app feature - start */}
            <div className="app-feature app-feature-1">
                <div className="app-feature-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading heading="The advantages of our SMM Panel" subHeading="Lý do bạn nên chọn chúng tôi" icon="las la-cog" additionalClasses="center width-100" />
                            </div>
                        </div>
                        <div className="row gx-5 gy-5">
                            {features.map((element, key) => {
                                return (
                                    <div className="col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-10 offset-1" key={key}>
                                        <AppFeatureSingle icon={element.icon} heading={element.heading} excerpt={element.excerpt} containerClass="app-feature-single-2" link={element.to} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {/* app feature - end */}

            {/* pricing section - start */}
            <div className="pricing-section">
                <div className="pricing-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon="las la-tags"
                                    heading="Customer tier privileges"
                                    subHeading="Cấp bậc ưu đãi khách hàng"
                                    additionalClasses="center c-white"
                                    mainHeadingClass="c-white"
                                    subHeadingClass="c-white"
                                />
                            </div>
                        </div>
                        <PricingSlider />
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation />
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{ backgroundImage: `url(${background_image_1})` }}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* pricing section - end */}

            {/* cta section - start */}
            <div className="cta-section mt-5">
                <div className="cta-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading icon="las la-cloud-download-alt" heading="What are you still waiting for?" subHeading="Bạn còn đợi gì nữa?" additionalClasses="center width-71" />
                                <DownloadButton color="c-white" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* cta section - end */}
        </>
    )
}

export default Index
